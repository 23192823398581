import Azorte from "../Images/azorte.png"
import trends from "../Images/trends.png"
import eleven from "../Images/eleven.png"
import akcapital from "../Images/ak capital.png"
import reliance from "../Images/reliancedigi.png"
import bro from "../Images/jb brothers.png"
import livspace from "../Images/livspace.png"
import square from "../Images/square.png"
import catalyst from "../Images/catalyst.png"
import blushlace from "../Images/Reliance-Blushlace-1.jpeg"
import relianceretail from "../Images/relianeretail.png"
import Muez from "../Images/muex.png"
import aditya from "../Images/aditya.jpeg"
import arrivae from "../Images/download.jpeg"
import bts from "../Images/bts.png"

export default function Logos() {
  return (
    <div className="bg-bronze py-34 sm:py-32 px-4">
      
      <div className="mx-auto max-w-4xl">
        <div className="-mx-6 grid grid-cols-2 gap-0 overflow-hidden sm:mx-0 sm:rounded-md md:grid-cols-3 ">
          <div className="bg-gray-400 p-8  flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 mix-blend-color-burn aspect-auto object-contain"
             
              src={catalyst}
              alt="Transistor"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-8 flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6  aspect-auto object-contain"
             
              src={blushlace}
              alt="Transistor"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-6  flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 mix-blend-color-burn aspect-auto object-contain"
              src={arrivae}
              alt="Reform"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-6 flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 aspect-auto object-contain"
           
              src={bro}
              alt="Tuple"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-6 flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 aspect-auto mix-blend-color-burn object-contain"
           
              src={relianceretail}
              alt="Tuple"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-6 flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 aspect-auto object-contain"
              src={eleven}
              alt="Laravel"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-4 flex justify-center sm:p-10">
            <img
              className="max-h-16 w-3/6 aspect-auto object-contain"
              src={reliance}
              alt="SavvyCal"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-6 flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 mix-blend-color-burn  aspect-auto object-contain"
              src={square}
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-6 flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 mix-blend-color-burn aspect-auto object-contain"
                src={akcapital}
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-6 flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 aspect-auto object-contain"
              src={trends}
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-6 flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 mix-blend-color-burn aspect-auto object-contain"
               src={livspace}
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-10 flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 aspect-auto object-contain"
              src={Azorte}
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-10 flex justify-center  sm:p-10">
            <img
              className="max-h-20 w-3/6  mix-blend-color-burn aspect-auto object-contain"
              src={bts} 
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-6 flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 mix-blend-color-burn aspect-auto object-contain"
              src={Muez}
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
          <div className="bg-gray-400 p-6 flex justify-center sm:p-10">
            <img
              className="max-h-12 w-3/6 mix-blend-color-burn aspect-auto object-contain"
              src={aditya}
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
